import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  async (config: any) => {
    return config
  },
  (error: any) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  async (config: any) => {
    return {
      ...config,
      success: config.status === 200 || config.status === 201,
    }
  },
  async (error: any) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
