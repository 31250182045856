export default function TermsContent() {
  return (
    <div>
      <h1>Terms of Use</h1>

      <p>Please read these Terms and Conditions carefully. </p>
      <p>
        You agree to abide by the terms and conditions described herein, as well as any terms and
        conditions incorporated by reference, when using the Website and/or App.{' '}
      </p>

      <p>
        If you do not agree to any part of these Terms, you must stop using our Website and
        immediately delete our App.
      </p>

      <p>
        We understand that when you register and/or click the "Register" or other registration
        button on our Website/Application or begin using the Website/Application, you agree to the
        current Terms.
      </p>

      <div>
        <h2>1. Definitions and Interpretations</h2>
        <ol>
          <li>
            <p>
              1.1 These Terms set forth the meanings of the following definitions, which will be
              used throughout:
            </p>
            <p>
              <b>"Affiliate"</b> means any Person, individual, corporation, partnership, firm,
              association, unincorporated organisation or other legal entity directly or indirectly
              controlling, controlled by or under common control with such Person. <br /> <br />{' '}
              <b>"Agreement"</b> means the agreement between you and us consisting of the following
              documents: The Terms, all references in the Terms to any of our documents, elements of
              the Website, Applications when using the Services, Products, our fees, costs and any
              other addendum and other terms, conditions and other documents identified by us as
              part of the Agreement. <br /> <br /> <b>"AML and FT requirements"</b> means any
              applicable law relating to money laundering, terrorist financing, proliferation of
              weapons of mass destruction, sanctions, tax evasion, fraud, bribery, corruption,
              trafficking in arms, human beings or wildlife, drugs, sanctions evasion, slavery and
              any other financial crime regulation. <br /> <br /> <b>"Application"</b> means the web
              and Android/IOS mobile device software (with all updates, upgrades, versions, etc.)
              developed and owned by us that allows you to access our Services, Products. <br />{' '}
              <br /> <b>"Account"</b> means the account that provides the services selected by us
              for the purpose of entering all credits earned by the account holder in accordance
              with the applicable terms and conditions of use of the application. <br /> <br />{' '}
              <b>"Confidential Information"</b> means any information disclosed by one party (the
              "Disclosing Party") to another party (the "Receiving Party") that is not known to the
              public (regardless of its source or form of transmission). Confidential Information
              includes any information that is not publicly available and cannot be lawfully
              obtained by third parties from other sources. By default, all information exchanged
              between the Parties shall be considered confidential in view of its nature and
              character, unless otherwise established by the Agreement, the Disclosing Party or the
              law. <br /> <br /> <b>"Cyberattacks"</b> include, but are not limited to, interference
              through social engineering, forking, phishing, hacking, smurfing, Sibylline attacks,
              distributed denial of service, malware, misinformation campaigns, spoofing, majority
              mining, consensus-based or other mining attacks, double-spending, etc. <br /> <br />{' '}
              <b>"Encumbrances"</b> means any pledge, surety, guarantee, mortgage, lien, lien or any
              other security encumbering your decree backed cryptocurrencies, whether imposed by
              agreement or regulation or by governmental authorities or the enforcement of a court
              order, that gives another person, institution priority or priority over creditors,
              including any right of set-off.
              <br /> <br /> <b>"Exchange"</b> means the buying and selling of supported
              cryptocurrencies, fiat money. Exchange represents order transactions in
              cryptocurrency/fiat or fiat/cryptocurrency or fiat/cryptocurrency or
              cryptocurrency/cryptocurrency, the list of which is defined and updated by us for use
              in connection with the Affiliates' services through the Metawine Platform <br />{' '}
              <br /> <b>"Fiat"</b> means any asset that is a government-issued currency that is
              commonly used and accepted as a medium of exchange in a country or territory of issue.{' '}
              <br /> <br /> <b>"Force Majeure"</b> means any event that is beyond our and your
              reasonable control and prevents or delays us and you from performing our or your
              obligations under the Agreement (earthquake, fire, explosion, flood, landslide,
              lightning, epidemics, pandemic, acts of war and terrorism, declared or undeclared,
              civil unrest, embargoes, natural disasters, Internet failures or outages,
              cyber-attacks, DDOS attacks and similar Internet attacks having adverse effects, the
              enactment or any change in applicable law, etc.). <br /> <br /> <b>"Fork"</b> means
              the modification of existing source code or the creation of new or additional source
              code for a blockchain that may result in the creation of more than one version of that
              cryptocurrency, material changes in the function, meaning, name of that
              cryptocurrency, etc. .
              <br /> <br /> <b>"Instruction"</b> means your request to transfer a supported
              cryptocurrency or WinePoints from your wallet to the address you provide in such
              request. <br /> <br /> <b>"Intellectual Property Rights"</b> means Metawine's
              intellectual property rights related to intangible assets owned by Metawine <br />{' '}
              <br /> <b>"WinePoints"</b> means Metawine tokens, not hybrid tokens, are final and
              non-refundable. Detailed information about WinePoints tokens is reflected in the
              documentation and information on the website/application. WinePoints tokens do not
              legally qualify as a security and do not meet the definition of electronic money.
              WinePoints do not grant their owners influence or rights to develop or manage Metawine{' '}
              <br /> <br />
              <b>"Metawine Platform"</b> means the website and web application based digital
              platform created by Metawine that provides multiple tools, features and allows users
              to interact, act on platform features and access services. Partners. Metawine's
              platform is integrated with Partners' service platforms to enable users to utilise
              Partners' services. <br /> <br /> <b>"NFT"</b> means a non-replaceable Metawine 2.0
              smart contract token that is or may be offered on the Website and/or App. <br />{' '}
              <br /> <b>"Service Provider Partners"</b> means companies with appropriate
              permissions, licences, registrations, technical solutions that provide Partner
              Services and with whom the Metawine Platform has proper API integration to allow users
              to access Partner Services. <br /> <br /> <b>"Affiliate Services"</b> means account
              opening, maintenance and closing services, payment processing to and from accounts,
              other services provided by financial institutions; cryptocurrency/cryptocurrency, fiat
              money exchange services; cryptocurrency custody services, cryptocurrency transfer
              services, etc. <br /> <br /> <b>"Person"</b> means an individual, partnership, limited
              partnership, corporation, limited liability company, limited liability company, joint
              stock company, unincorporated organisation or association, trust or joint venture or
              other forms of legal entity, etc. Person also means the representatives, successors to
              a Person. or permitted assigns. <br /> <br /> <b>"Products"</b> means NFT and other
              Metawine products that are or may be offered on the Website and/or App.
              <br /> <br /> <b>"P2P"</b> means smart contracts that allow users to interact directly
              with each other in the sale/purchase of Metawine <br /> <br /> <b>"Services"</b> means
              the services to access the Metawine platform as well as other Metawine services
              offered on the Website/web Application. The Service Term does not apply to the
              services of Affiliates. <br /> <br /> <b>"Supported Cryptocurrency"</b> means a
              cryptographically protected digital representation of value or contractual rights that
              uses distributed ledger technology and may be transferred, stored or traded
              electronically without conditions or restrictions, a list of which is defined and
              updated by us for use in connection with our Services, Goods, Partner Services through
              the Metawine Platform <br /> <br /> <b>"User"</b> means any Person using our Website
              and/or App. <br /> <br /> <b>"Wallet"</b> means a cryptocurrency wallet created by you
              with unique addresses that you have obtained, which provides you with information
              about and the ability to manage the supported cryptocurrencies you own. When you
              initiate the opening of a wallet through the Metawine platform, it is created using a
              smart contract in a blockchain outside the Metawine platform. When you open a Wallet,
              it is integrated and connected to the Metawine website/application for the purposes of
              the Terms, the integration of your Wallet with the Metawine platform allows you to
              have a personal account reflecting all your relevant personal data and your Wallet
              account details.
            </p>
          </li>

          <li>
            <p>
              1.2 Other undefined terms and definitions found in the text of the TOU shall be
              defined as set out in the relevant provisions of the TOU and otherwise construed by
              the Parties in accordance with law.
            </p>
          </li>
          <li>
            <p>1.3 Interpretations:</p>
            <p>
              <ul>
                <li>
                  <p>
                    Headings are used in the Terms for convenience only and do not affect the
                    interpretation of the provisions of the Terms.
                  </p>
                </li>

                <li>
                  <p>
                    In the Terms of Use, you and Metawine are referred to collectively as the
                    "Parties" and individually as a "Party".
                  </p>
                </li>

                <li>
                  <p>
                    Unless the context otherwise requires, words in the singular include the plural
                    and words in the plural include the singular.
                  </p>
                </li>

                <li>
                  <p>
                    Unless the context otherwise requires, a reference to one gender shall include a
                    reference to the other genders.
                  </p>
                </li>

                <li>
                  <p>
                    Where the words "includes" are used in the Terms of Use, including or, in
                    particular, followed by the word "without limitation".
                  </p>
                </li>

                <li>
                  <p>
                    Where the context permits, the words "other" and "otherwise" are illustrative
                    and shall not limit the meaning of the words preceding them.
                  </p>
                </li>
              </ul>
            </p>
          </li>
        </ol>

        <h2>2. Introductory provisions</h2>
        <ol>
          <li>
            <p>
              2.1. These Terms and Conditions ("T&Cs") govern the contractual relationship between
              the parties, BUSINESS TECHNOLOGY INVESTMENT LTD (a company incorporated Unit B Broad
              Oak Business Park, Redbrook Maelor, Whitchurch, Shropshire, United Kingdom,
              registration number 06795173,) ("Metawine", "we", "us", "we" or "our") and you, as a
              customer, visitor, if you access, use our website, application ("you", "your",
              "client", "customer", "user" or "Customer.) ").
            </p>
          </li>

          <li>
            <p>2.2 Please note that Metawine does NOT provide:</p>
            <p>
              <ul>
                <li>
                  <p>
                    cryptocurrency storage services, cryptocurrency transfer services, other
                    ancillary services. In such cases, we engage Service Provider Partners.
                  </p>
                </li>
              </ul>
            </p>
          </li>

          <li>
            <p>
              2.3 These Metawine Terms and Conditions govern your rights and obligations in
              connection with your use of Metawine's Services and products, which are generally
              available through the website https://metawine.pro (the "Website") or the App.
            </p>
          </li>

          <li>
            <p>
              2.4 By accessing or using our Services or Products, you confirm that you have accessed
              the Terms and Conditions (by posting them on the Website) prior to using our Services
              or Products and that you have had sufficient time to carefully review them in advance
              and can easily copy them.
            </p>
          </li>

          <li>
            <p>
              2.5 The Terms are a public offer incorporating standard terms and conditions. This
              offer is accepted when an action is taken that expresses your will, such as starting
              to use the Website or App, indicating your agreement to the Terms and Conditions.
            </p>
          </li>

          <li>
            <p>
              2.7 By using the Website or App, you confirm that the Terms and Conditions do not
              contain any unexpected terms, do not exclude your rights and options that are usually
              provided in similar agreements, do not establish other provisions that violate the
              principle of equality of arms, cause an imbalance of parties, interests or contradict
              the criteria of reasonableness, good faith and fairness.
            </p>
          </li>

          <li>
            <p>
              2.8 We reserve the right to change or modify these Terms and Conditions at any time at
              our sole discretion and without prior notice to you. Your continued use of the Website
              and/or the App will constitute your agreement to such modified Terms.
            </p>
          </li>

          <li>
            <p>
              2.9 We reserve the right, in our sole discretion, to modify, suspend or cancel the
              Website, the App or any part of the Website, the App or the Services, Products without
              prior notice to you and to block or prevent your future access to and use of the
              Website, the App.
            </p>
          </li>

          <li>
            <p>
              2.10. These Terms do not alter the terms of any other agreement you may have with us
              in relation to the Products, Services or anything else.
            </p>
          </li>

          <li>
            <p>
              2.11. Metawine emphasises that misconduct is strictly prohibited and may result in
              serious consequences for the user who commits it
            </p>
          </li>

          <li>
            <p>
              2.12. The Services and Products provided by Metawine are intended only for persons
              over 21 years of age. By registering on the Site and/or the App, the User confirms
              that he/she is over 21 years of age. Use of the Metawine Services and purchase of
              Products is prohibited to anyone under the age of 21. If you are under 21 years of
              age, please stop using the Website and App immediately. By registering on the
              Website/App, you confirm that all information provided is correct, accurate and up to
              date. You are solely responsible for the accuracy, truthfulness and correctness of the
              information on the Website/Application.
            </p>
          </li>

          <li>
            <p>
              2.13. To view the full range of Products and Services, you can find the Website and
              the App.
            </p>
          </li>

          <li>
            <p>
              2.14. You must comply with all of our requirements relating to the Services, Products,
              viz. installation.
            </p>
          </li>
        </ol>

        <h2>3. Metawine platform</h2>
        <ol>
          <li>
            <p>3.1 You can find information about opening a wallet on our website and app.</p>
          </li>

          <li>
            <p>
              3.2 We cannot promise or take responsibility for any supported cryptocurrency or the
              values and rates of WinePoints. Due to the uncertainty, unpredictability and
              volatility of cryptocurrency, cryptocurrency transactions and storage are high risk
              activities. You agree to monitor any changes relevant to these activities, including
              but not limited to the status and balance of your Wallet.
            </p>
          </li>

          <li>
            <p>
              3.3 You will be solely and absolutely responsible for the secure storage, backup and
              use of your password and for the security of your email when accessing your personal
              account on the Metawine platform for security purposes, we implement a minimum
              two-factor indexing.
            </p>
          </li>

          <li>
            <p>
              3.4 In the event of unauthorised access to your personal account on the Metawine
              Platform and/or any other breach of security, you must notify us immediately via the
              feedback form <a href="mailto:contact@metawine.pro">contact@metawine.pro</a> . In
              addition, the user agrees to provide all evidence of such unauthorised access upon
              request.
            </p>
          </li>

          <li>
            <p>
              3.5 If you fail to notify us of an incident as provided in cl. 3.4, under no
              circumstances will we be liable for any damage caused by you as a result of misuse of
              his login details by another person. That is, loss of access to a user account or
              unauthorised access to another person's personal account is the sole responsibility of
              the user.
            </p>
          </li>

          <li>
            <p>3.6 Wallet, Card, Exchange</p>
            <ol>
              <li>
                <p>
                  3.6.1 We do not provide cryptocurrency wallet, exchange, card services and
                  therefore are not required to conduct "know your customer" checks or comply with
                  AML and CTF requirements. However, Partners providing services may require to go
                  through a verification process and ask you to provide them with the necessary
                  information and documentation. Please note that such information and documentation
                  you provide to them must be complete, accurate and up to date. You agree that
                  Provider Partners have the right to verify, supplement the information you
                  provide, obtain information about you themselves or from third parties to update
                  their records, or comply with applicable rules and internal policies. etc. If at
                  any time Service Partners believes that your information or document is
                  incomplete, outdated or inaccurate, they have the right to contact you and request
                  additional information and documents. Failure to provide information or documents
                  may affect your right to use their services.
                </p>
              </li>
              <li>
                <p>
                  3.6.2 Given that we do not open Wallets for you on our Metawine platform we do not
                  store any of your passwords, cid phrases or other specifics of your access to your
                  Wallet. Given this, we will not be able to restore your access to your Wallet if
                  you lose your passwords, start phrases and other features of your access to your
                  Wallet and we will not be liable for the negative consequences of this. Therefore,
                  please take care of your passwords, cid-phrases and other features of your access
                  to your Wallet.
                </p>
              </li>

              <li>
                <p>
                  3.6.3 When you initiate an Instruction, we do not process such Instructions. This
                  role is fulfilled by our Service Provider Partner. This also relates to the
                  receipt of supported cryptocurrencies, WinePoints transfers.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>3.7 Token Mining</p>
            <ol>
              <li>
                <p>
                  3.7.1 You accept and agree that we may offer minting in respect of WinePoints
                  which is linked to the purchase and storage of NFTs and the accumulation and
                  freezing of tokens is based on a smart contract on the Metawine platform. The
                  minting terms are determined by us and you can review them before purchasing NFTs.
                  If you accept the minting terms and purchase an NFT, you authorise us, and we are
                  entitled, to process the tokens resulting from the purchase of the NFT in
                  accordance with the minting terms determined by us.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>3.8. Metawine System Services</p>

            <ol>
              <li>
                <p>
                  3.8.1. Metawine's rating is linked to WinePoints , Metawine (the WinePoints
                  ecosystem), which you can access when you purchase NFT products.
                </p>
              </li>

              <li>
                <p>
                  3.8.2. The Metawine system tools contains a distributed database that allows users
                  to store, share and maintain information about their achievements, statistics,
                  benefits, products based on levels defined by Metawine, with WinePoints playing a
                  key role in this system.
                </p>
              </li>

              <li>
                <p>
                  3.8.3. In Metawine system, you can receive system level, membership level,
                  rewards, cashback etc., details of which are set out in the Metawine system
                  features on the website, application.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>3.9 Other Services</p>
            <ol>
              <li>
                <p>
                  3.9.1 We may from time to time offer and implement other Services not mentioned in
                  the Terms which will be offered to users. For this purpose, we may update these
                  Terms or provide the Services to users on the Website and/or the App without
                  updating the Terms.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>3.10. Sale of WinePoint tokens by us.</p>
            <ol>
              <li>
                <p>
                  3.10.1. Frequently, we make decisions about the sale of WinePoint tokens by us
                </p>
              </li>
              <li>
                <p>
                  3.10.2. We represent and warrant that the WinePoint Tokens are owned by us prior
                  to their minting and distribution to you, free of any encumbrances. We have not
                  given any opportunity or right to any person to accept or purchase such WinePoint
                  tokens or any part thereof.
                </p>
              </li>

              <li>
                <p>
                  3.10.3. You expressly acknowledge and represent that you have carefully reviewed
                  all WinePoint related documentation and information on the Website, fully
                  understand the risks, costs and benefits associated with the purchase of WinePoint
                  tokens
                </p>
              </li>

              <li>
                <p>
                  3.10.4. You represent and warrant that the funds you use to purchase, obtain
                  WinePoint tokens are not derived from or associated with any illegal activity,
                  including but not limited to money laundering or terrorist financing, and you will
                  not use WinePoint to fund, participate in, or otherwise support any illegal
                  activity.
                </p>
              </li>

              <li>
                <p>
                  3.10.5. WinePoint cannot be sold/bought through a decentralised exchange at this
                  time.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>3.11. NFT.</p>
            <ol>
              <li>
                <p>
                  3.11.1. We offer NFT, the terms and conditions of which are detailed on the
                  website/application.
                </p>
              </li>

              <li>
                <p>
                  3.11.2. NFTs are intended for use in products offered by the Metawine ecosystem.
                  The NFT will remain subject to the Terms and Conditions on an ongoing basis and
                  any purchase, acquisition, possession or use of the NFT by a subsequent holder
                  will be subject to the Terms and Conditions.
                </p>
              </li>

              <li>
                <p>
                  3.11.3 We may release NFTs from time to time either individually or as packages.
                  Issues may be at varying prices, including discounts, promotional gifts or
                  reserves for future issues. We reserve the right to issue NFTs to Affiliates,
                  subject to any restrictions we deem appropriate, or without restriction.
                </p>
              </li>

              <li>
                <p>
                  3.11.4 We do not represent or warrant that your access to the products offered by
                  the Metawine ecosystem will be uninterrupted or continue in the future. The use of
                  NFT is closely tied to the operation of products offered by the Metawine
                  ecosystem, and we cannot guarantee that such products or any of their promoted
                  elements will ever be publicly launched or supported in the future.
                </p>
              </li>
            </ol>
          </li>
        </ol>

        <h2>4. Records</h2>
        <ol>
          <li>
            <p>
              4.1 All records of Instructions, Transactions are reflected in your personal account
              on the Metawine platform for your information only.
            </p>
          </li>

          <li>
            <p>
              4.2 You can access your wallet, transaction history on both the website and the App.
              It is your responsibility to check your records for errors. You must report any
              erroneous or unauthorised instructions, transactions to the Partners providing
              services to us as soon as possible.
            </p>
          </li>
        </ol>
        <h2>5. Referral Programme</h2>
        <ol>
          <li>
            <p>
              5.1 We offer a referral programme for our users, which is a form of partnership in
              which you can invite other customers to use our Services and Products for proper
              promotion and profit as set out on the Website/Application, which is provided as a
              reward each time they use your unique referral link. Further information and details
              of our referral programme can be found on our Website/Application.
            </p>
          </li>

          <li>
            <p>
              5.2 Our Metawine system contains various incentives and profits based on our referral
              programme. Your personal account on the Metawine platform or other fields may also
              contain details of our referral programme.
            </p>
          </li>

          <li>
            <p>
              5.3 Our referral programme is treated as a decent payment for your advertising and
              marketing services that you provide to us.
            </p>
          </li>
        </ol>
        <h2>6. Charges</h2>
        <ol>
          <li>
            <p>
              6.1. We are not responsible for fees charged by Affiliates providing services. If you
              have any questions about fees, please contact the Service Provider Partners.
            </p>
          </li>
        </ol>
        <h2>7. Rights and Responsibilities</h2>
        <ol>
          <li>
            <p>
              7.1 You are responsible for maintaining the confidentiality of your
              login/password/access to your personal account on the Metawine Platform etc.
            </p>
          </li>

          <li>
            <p>
              7.2 You and we will not: <br />
              <ul>
                <li>
                  <p>engage in market misconduct;</p>
                </li>

                <li>
                  <p>provide false, inaccurate, incomplete or misleading information;</p>
                </li>

                <li>
                  <p>
                    use the Services, Products to engage in illegal, unlawful or immoral behaviour;
                  </p>
                </li>

                <li>
                  <p>
                    use the Services, Products to download content that contains or is infected with
                    viruses, malicious codes, distribute any unwanted or unauthorised advertising,
                    promotional or marketing materials, disassemble, decompile, reconstruct;
                  </p>
                </li>

                <li>
                  <p>
                    take any action that imposes an unreasonably heavy load or strain on the
                    infrastructure of the Site/Application, or interfere with data or information
                    belonging to other users of the Services, Products.
                  </p>
                </li>
              </ul>
            </p>
          </li>

          <li>
            <p>
              7.3 We may not block (i.e. restrict your right to withdraw, transfer, exchange, etc.)
              your use of your Wallet, Card and any funds stored on them. You understand that
              Service Provider Partners may have such blocking rights as permitted by applicable
              law, their terms of service, internal policies, procedures, in particular, but not
              limited to, the cases defined in the AML and FT Requirements.
            </p>
          </li>
        </ol>

        <h2>8. Disclaimer</h2>
        <ol>
          <li>
            <p>
              8.1. Your use of our Services and Products is at your own risk and subject to, among
              other things, the disclaimers set out below and other provisions of these Terms:
            </p>

            <ol>
              <li>
                <p>8.1.1. You acknowledge the significant risks associated with cryptocurrency.</p>
              </li>

              <li>
                <p>
                  8.1.2 You understand and agree that cryptocurrencies are potentially subject to
                  legal and regulatory risks, the treatment of cryptocurrencies may be changed by
                  regulations in various jurisdictions, it is currently impossible to predict their
                  effect.
                </p>
              </li>

              <li>
                <p>
                  8.1.3 Metawine is not authorised or regulated by any financial authority and does
                  not provide any financial, tax, employment, legal or investment advice. You agree
                  that Metawine will not be, and shall not be deemed to be, your financial adviser
                  or fiduciary. You understand that any decisions made are at your sole and
                  exclusive risk.
                </p>
              </li>

              <li>
                <p>
                  8.1.4 You understand and accept the risks that cryptocurrencies may be subject to
                  forking or attacks on the security, integrity or operation of networks that are
                  beyond our control, which may affect the price/value of the cryptocurrency, cause
                  the network/platform to shut down. You agree that in the event of a Fork, we may
                  temporarily suspend any access to the Services and Products.
                </p>
              </li>

              <li>
                <p>
                  8.1.5 Trading and holding cryptocurrency is a high-risk activity and you confirm
                  that you are aware of and acknowledge this and act at your own discretion,
                  considering and assessing, amongst other things, your personal skills, market
                  conditions and your financial condition. Neither we nor our respective third
                  parties shall be liable in this regard for any results of your activities
                  resulting from your use of the Services, Products, services of our Service
                  Provider Partners. Cryptocurrency is not legal tender, cannot be backed by
                  physical assets or guaranteed by the government, some may not be freely or widely
                  circulated, lost or stolen cryptocurrencies are generally irreversible, and you
                  understand all of this. You also understand and accept the risk of unpredictable
                  very high volatility of a cryptocurrency within a short period of time and that
                  the value of a cryptocurrency may be completely and permanently lost if the market
                  for that cryptocurrency disappears, inability to sell the cryptocurrency due to
                  lack of liquidity or restriction/control by government authorities.
                </p>
              </li>

              <li>
                <p>
                  8.1.6. You also understand that cryptocurrencies are based on different types of
                  distributed ledger technologies, some of which are open source software based on
                  experimental technology - blockchain, and you assume the risk of technical flaws
                  in the cryptocurrency technology, attack by malicious hackers, fraud or
                  Cyberattack, changes in consensus protocol or algorithms, etc.
                </p>
              </li>

              <li>
                <p>
                  8.1.7 You acknowledge and accept the risk that any Instruction, Exchange, P2P
                  Transaction will be unauthorised or transmitted by an unauthorised person and the
                  risk that an Instruction, Exchange, P2P Transaction may be processed twice if you
                  send the same Instruction, Exchange, P2P Transaction in different forms and the
                  risk that any information sent by electronic means cannot be guaranteed to be
                  secure, virus-free or delayed.
                </p>
              </li>

              <li>
                <p>
                  8.1.8 You acknowledge and agree that (a) our representations and warranties
                  contained in the Terms are our sole and exclusive representations and warranties
                  with respect to the Services and Products under these Terms, (b) all
                  representations and warranties, express or implied, of any nature whatsoever,
                  other than those specifically set forth in these Terms are expressly disclaimed,
                  and (c) any data, information or any other material or information provided to or
                  addressed to you and the App are provided "as is" and "as available".
                </p>
              </li>

              <li>
                <p>
                  8.1.9 Any Services, Products, data, tools on the Website and App are provided "as
                  is" and "as available". We decide when to update information on the Website, the
                  App. To the maximum extent permitted by law, we make no express or implied
                  warranties of any kind as to the accuracy, compatibility, reliability, integrity,
                  wholeness, usefulness of our Website and Application. Therefore, you entrust such
                  information solely at your own risk.
                </p>
              </li>

              <li>
                <p>
                  8.1.10. You understand that using the Application on a jailbroken device may cause
                  security issues and may result in fraudulent transactions.
                </p>
              </li>

              <li>
                <p>
                  8.1.11. Whilst we use reasonable endeavours to ensure that the Website and the
                  Application are secure and free from bugs, viruses and other malware, we make no
                  warranty in this regard. Users assume responsibility for their own security, the
                  security of their personal data and their computers and for any other potential or
                  apparent risks.
                </p>
              </li>

              <li>
                <p>
                  8.1.12. You acknowledge and agree that the relationship between you and us set out
                  in or relating to the Agreement does not create any fiduciary, fiduciary or
                  similar duty on our part in your favour, nor is there any duty that would require
                  us to assume greater responsibility than that set out in the Agreement. The
                  Agreement does not create any partnership, joint venture, consultant, agency or
                  trustee relationship or any similar relationship between you and us. You
                  acknowledge and agree that we do not represent or act for and/or on behalf of our
                  Service Provider Partners.
                </p>
              </li>

              <li>
                <p>
                  8.1.13. You understand and agree that we may receive enquiries, notices from tax
                  and other authorities and may be required to provide certain information about
                  you.
                </p>
              </li>

              <li>
                <p>
                  8.1.14. On our Website/Application, we include or may include links to third party
                  websites, the Application. These links or references are or may be announced,
                  published and displayed/shown on our Website/Application for general information
                  and reference purposes only. As such, we have no control over the outgoing
                  websites and applications and are not responsible for their content. We are not
                  responsible for any internet sites hyperlinked to through the Terms and
                  Conditions, Website or App, nor are we liable for any losses incurred in
                  connection with those sites.
                </p>
              </li>

              <li>
                <p>
                  8.1.15. You understand that if a transaction is made in any cryptocurrency issued
                  by a party subject to foreign laws, or transactions made on markets in other
                  jurisdictions, governmental authorities may impose certain restrictions,
                  deductions related to exchange controls, moratoriums or other imposed actions.
                  With this in mind, you should ensure that you are aware of relevant regulations
                  and laws before initiating Instruction, Exchange and P2P transactions.
                </p>
              </li>

              <li>
                <p>
                  8.1.16. WE DO NOT make or expressly disclaim all representations and warranties,
                  whether express, implied or statutory, other than those given in the Terms and
                  Conditions; AND WITH RESPECT TO WinePoint, WE SPECIFICALLY DO NOT MAKE ANY
                  REPRESENTATIONS, WARRANTIES OR WARRANTIES, EXPRESS, IMPLIED OR LEGAL, INCLUDING,
                  BUT NOT LIMITED TO, ANY REPRESENTATIONS OR WARRANTIES OF TITLE, NON-INFRINGEMENT,
                  MERCHANTABILITY OR FREEDOM FROM ANY DEFECT THEREIN.
                </p>
              </li>

              <li>
                <p>
                  8.1.17. To the fullest extent permitted by applicable law, in no event shall we or
                  any third party engaged by us be liable for any special, incidental, indirect or
                  consequential damages (including, without limitation, damages for loss of profits,
                  loss of data or other information, business interruption, personal injury, loss of
                  privacy arising out of or in any way connected with the use of or inability to use
                  the Services, Products, third party pr Products or otherwise in connection with
                  any provision of the Agreement), even if we or any third parties engaged by us
                  have been advised of the possibility of such damages and even if the remedy fails
                  of its essential purpose.
                </p>
              </li>

              <li>
                <p>
                  8.1.18. We shall not be liable for any damages arising directly or indirectly
                  from: (a) any act or omission by you as purchaser, recipient of NFT or any error,
                  negligence or misconduct by you; (b) failure of transmission or communication
                  facilities; (c) any other cause or causes beyond our control; (d) our reliance on
                  any instructions, notices or communications which it believes to be from you; (e)
                  governmental restrictions; exchange, regulatory or market rules; suspension of
                  trading; acts of war; terrorist activities; strikes or any other conditions
                  outside of the
                </p>
              </li>

              <li>
                <p>
                  8.1.19. The Services and Products are provided to you "AS IS" and "AS AVAILABLE",
                  with all faults and defects, without warranty of any kind. To the fullest extent
                  permitted by applicable law, we, on behalf of ourselves and our Affiliates and our
                  and their respective licensors and service providers, expressly disclaim all
                  warranties, express, implied, statutory or otherwise, with respect to the
                  Services. Products, including all implied warranties of merchantability, fitness
                  for a particular purpose, title and non-infringement, and warranties that may
                  arise from business operations, exploitation, use or trade practices. Without
                  limiting the foregoing, we make no warranty or representation that the Services,
                  Products will meet your requirements, achieve any intended results, be compatible
                  or work with any other software, applications, systems or Services, the Products
                  will operate uninterrupted, meet all performance and reliability standards, are
                  error free, and any errors or defects can or will be corrected.
                </p>
              </li>

              <li>
                <p>
                  8.1.20. All rights not expressly granted under the Agreement are reserved.
                  Therefore, you are prohibited from using the Services and Products in any manner
                  not expressly and unambiguously authorised by T&C.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <h2>9. Intellectual Property</h2>
        <ol>
          <li>
            <p>
              9.1 You acknowledge and agree that Metawine is the sole owner (except to the extent
              owned by third party licensors, including third party technology providers) of all
              right, title and interest in and to the Intellectual Property Rights. You will not
              receive any rights to intellectual property rights other than the limited rights
              granted to you under Metawine's licence. By using the Website and App, you understand
              and accept that such content is protected by copyright, trade marks and any other
              intellectual property rights.
            </p>
          </li>

          <li>
            <p>
              9.2 Subject to your compliance with the Terms and Conditions, you are granted a
              limited, revocable, non-exclusive, royalty-free and non-transferable licence to access
              and use the Website and App during the term of the Agreement for the purposes set out
              in and in the manner. in accordance with the Agreement.
            </p>
          </li>

          <li>
            <p>
              9.3 You shall not: <br />
              <ul>
                <li>
                  <p>
                    alter, maintain, improve or otherwise modify the Website and/or the Application;
                  </p>
                </li>

                <li>
                  <p>
                    disassemble, decompile, reverse engineer, reconstruct, copy, bug fix, correct,
                    update, transmit, broadcast or create derivative works based on the Website
                    and/or the Application;
                  </p>
                </li>

                <li>
                  <p>
                    otherwise take some action to discover any equivalent of the Website or
                    Application.
                  </p>
                </li>
              </ul>
            </p>
          </li>

          <li>
            <p>
              9.4 Nothing on our Website and App shall be construed as granting by implication,
              estoppel or otherwise any licence or right to use any logo, trademark or service mark
              displayed on our Website, App without the prior written consent of the owner.
            </p>
          </li>

          <li>
            <p>
              9.5 In the sale and disposal of WinePoint, we retain all right, title and interest in
              and to all of its intellectual property. When you purchase, obtain WinePoint, you may
              not use any of our intellectual property for any reason without our prior consent. we
              retain all right, title and interest in and to all of its intellectual property. When
              you purchase, receive WinePoint you may not use any of our intellectual property for
              any reason without our prior consent.
            </p>
          </li>
        </ol>
        <h2>10. Limitation of Liability</h2>
        <ol>
          <li>
            <p>
              10.1 We shall not be liable to you for any losses in connection with our Website, App,
              Services, Products and the Agreement arising from force majeure. You will not be
              liable to us for any losses in connection with the Agreement arising as a result of
              force majeure. We shall not be liable for the acts or omissions of our Service
              Provider Partners.
            </p>
          </li>

          <li>
            <p>
              10.2 Notwithstanding any damages you may suffer, the entire liability of Metawine and
              any third parties engaged by us and your exclusive remedy in respect of the foregoing
              shall be limited to the amount actually paid by you through the Services and Products.
            </p>
          </li>

          <li>
            <p>
              10.3 Nothing in the Terms shall be construed to exclude or limit either Party's
              liability for:
              <ul>
                <li>
                  <p>Fraud or wilful misrepresentation; </p>
                </li>

                <li>
                  <p>or Market Manipulation;</p>
                </li>

                <li>
                  <p>or Death or personal injury caused by its negligence;</p>
                </li>

                <li>
                  <p>
                    or any other liability which cannot be excluded or limited by applicable law;
                  </p>
                </li>

                <li>
                  <p>or any liability in any manner not permitted by law.</p>
                </li>
              </ul>
            </p>
          </li>

          <li>
            <p>
              10.4 To the fullest extent permitted by law, we and our respective third parties
              engaged by us shall have no liability to you in connection with the Website, App,
              Services and Products for any of the following, including but not limited to:
              <ul>
                <li>
                  <p>Loss of or damage to any data, database or software;</p>
                </li>

                <li>
                  <p>
                    Any business or commercial loss such as lost profits, income, revenue,
                    anticipated savings, contracts or business opportunities;
                  </p>
                </li>

                <li>
                  <p>
                    Any special, direct or indirect, incidental, punitive or consequential loss or
                    damage;
                  </p>
                </li>

                <li>
                  <p>
                    Any errors in the content of our Website, Application, any delays, failures,
                    malfunctions, unavailability or suspension of our Website, Application;
                  </p>
                </li>

                <li>
                  <p>
                    Your act or omission on our Website, Application, any transactions entered into
                    through our Website, Application;
                  </p>
                </li>

                <li>
                  <p>
                    Any other loss or damage that may be incurred as a result of your use of this
                    Website, Application, content, Services provided through this Website,
                    Application.
                  </p>
                </li>
              </ul>
            </p>
          </li>
        </ol>
        <h2>11. Links to other websites</h2>
        <ol>
          <li>
            <p>
              11.1 Our Services and Products may contain links to Service Partners, third party
              websites or services that are not owned or controlled by us.
            </p>
          </li>

          <li>
            <p>
              11.2 We have no control over, and assume no responsibility for, the content, privacy
              policies, or practices of any third-party web sites or services. You further
              acknowledge and agree that we shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused by or in connection
              with the use or enjoyment of any such content, goods or Services available on the
              Site. or through any such websites or Services.
            </p>
          </li>

          <li>
            <p>
              11.3 We strongly encourage you to read the terms and conditions and privacy policies
              of any third-party websites or Services you visit.
            </p>
          </li>

          <li>
            <p>
              11.4. We use or may use the facilities of other relevant third parties to provide our
              Services and Products to you.
            </p>
          </li>
        </ol>
        <h2>12. Restricted Countries and Activities</h2>
        <ol>
          <li>
            <p>
              12.1 We strictly comply with national and international sanctions rules. We do not
              provide Services, Products to users of sanctioned countries as per the current lists
              of the Office of Foreign Assets Control (OFAC) of the US Department of Treasury, the
              United Nations (UN), the EU and the Republic of Seychelles, among others. The list of
              countries can be changed.
            </p>
          </li>

          <li>
            <p>
              12.2 Users of the Website/Application are prohibited from performing the following
              actions, including those prohibited by other provisions of these terms and conditions:{' '}
              <ul>
                <li>
                  <p>
                    actions that will or may violate copyright, privacy, property and other rights
                    or interests of other users of this Website/Application, third parties;
                  </p>
                </li>

                <li>
                  <p>
                    actions that may cause inconvenience, financial loss or other damage to other
                    users of the Website/Application;
                  </p>
                </li>

                <li>
                  <p>
                    actions that have become or may be offensive to public order and morals or
                    violate laws, rules and regulations;
                  </p>
                </li>

                <li>
                  <p>false statements when using the Website/Application for any purpose;</p>
                </li>

                <li>
                  <p>unlawful use of email addresses, passwords or other personal information;</p>
                </li>

                <li>
                  <p>
                    actions that may interrupt the operation of the Website/Application or interfere
                    with the provision of our Services, Products.
                  </p>
                </li>
              </ul>
            </p>
          </li>
        </ol>
        <h2>13. Indemnification</h2>
        <ol>
          <li>
            <p>
              13.1 You agree to defend, indemnify, hold us harmless and thereby reimburse us for any
              loss, damage, costs, claims, litigation, fines, penalties or expenses, including legal
              fees, incurred by us or incurred by you which arise out of or relate to, directly or
              indirectly:
              <ul>
                <li>
                  <p>your use of the Website and/or the Application;</p>
                </li>
                <li>
                  <p>
                    your failure to fully and timely perform any of your obligations under the
                    Agreement, including your failure to fulfil your obligations in relation to any
                    use of the Services, Products;
                  </p>
                </li>

                <li>
                  <p>
                    any representations or warranties made by you under this Agreement may at any
                    time prove to be untrue or incorrect;
                  </p>
                </li>
                <li>
                  <p>
                    any breach by you of any law, rule, regulation or the rights of any third party.
                  </p>
                </li>
              </ul>
            </p>
          </li>
        </ol>
        <h2>14. Termination</h2>
        <ol>
          <li>
            <p>
              14.1 We may terminate or suspend your access immediately, without notice or liability,
              for any reason, including but not limited to your breach of the Agreement.
            </p>
          </li>

          <li>
            <p>14.2 Upon termination, your right to use the Services will immediately cease.</p>
          </li>

          <li>
            <p>
              14.3 You have the right to unilaterally terminate your relationship with us if you are
              not in debt to us.
            </p>
          </li>

          <li>
            <p>
              14.4 All provisions of the Terms that by their nature should survive termination shall
              survive termination, including but not limited to intellectual property, disclaimer,
              indemnity, limitation of liability and confidentiality. Confidentiality.
            </p>
          </li>

          <li>
            <p>
              14.5 We have the right at any time without liability to modify, suspend, discontinue
              the Services, Products if:
              <ul>
                <li>
                  <p>we have reason to believe that you have not complied with the Agreement;</p>
                </li>

                <li>
                  <p>
                    we have reason to believe that the transaction involves any illegal activity;
                  </p>
                </li>

                <li>
                  <p>any applicable law or internal policy requires us to do so.</p>
                </li>
              </ul>
            </p>
          </li>
        </ol>
        <h2>15. AML and FT requirements</h2>
        <ol>
          <li>
            <p>
              15.1 Please note that we are not required to perform identification, verification,
              authentication, due diligence and other AML/CFT measures in accordance with the AML
              and TF Requirements, nor do we control how you comply with the AML and TF
              Requirements. Please note that some of our service partners are required to comply
              with AML and TF requirements and take appropriate measures to prevent money laundering
              and terrorist financing.
            </p>
          </li>

          <li>
            <p>
              15.2 Please note that when using the Metawine Platform, we prohibit money laundering
              and any activity that facilitates money laundering or the financing of terrorists or
              any other criminal activity.
            </p>
          </li>
        </ol>
        <h2>16. Confidentiality</h2>
        <ol>
          <li>
            <p>
              16.1 The Receiving Party will keep the Confidential Information confidential and will
              not disclose the Confidential Information to any person or entity without the prior
              written consent of the Disclosing Party. We may disclose Confidential Information to
              our employees, officers, owners, contractors, contractors, consultants, Affiliates,
              advisors, auditors and other third parties ("Involved Parties") only (1) if necessary
              to provide the Services, Products, to comply with the internal procedures of either
              party related to such business relationship, legal requirements, and (2) to the extent
              based on business necessity, and (3) if we ensure that the Involved Parties have the
              commitment and technical, other wo Disclosure of Confidential Information is permitted
              without consent if such disclosure is permitted by law, legally binding judicial or
              governmental order/procedure, provided that the Receiving Party promptly notifies the
              Disclosing Party of such disclosure prior to disclosure, cooperates with the
              Disclosing Party in the event that the Disclosing Party chooses to contest such
              disclosure or seek a protective order with respect thereto.
            </p>
          </li>
        </ol>
        <h2>17. Electronic Communication</h2>
        <ol>
          <li>
            <p>
              17.1 When you register on the Website, Application, initiate an Instruction, Exchange,
              P2P transaction, etc., and for various communications, signing documents between the
              Parties, you and we agree to refer to and trust electronic signatures (which do not
              qualify for a qualified electronic signature) affixed through the Website,
              Application, other services offered by us. Such electronic signatures shall have the
              same legal force, validity, admissibility and enforceability as a handwritten
              signature. You acknowledge and agree that you are satisfied with the documents
              executed electronically. You consent to electronic communications between you and us.
              The parties understand and accept the risks associated with the use of electronic
              signatures that do not meet the requirements for a qualified electronic signature and
              acknowledge the documents signed by such signatures as legal, binding documents. We
              and you agree that the electronic signature referred to in this clause may be in the
              form of pressing a digital button confirming a deposit, withdrawal, exchange, etc. or
              ordering other Services, Products and/or one or two factor authentication (Google
              Authenticator). code, etc.), any other button.
            </p>
          </li>

          <li>
            <p>
              17.2 You acknowledge that all Instructions, Exchanges, P2P Transactions and other
              communications provided by you electronically are original written documents and when
              sent via the Website or App they are deemed to be signed by you with your electronic
              signature as set out in the Terms. You accept full responsibility for the security and
              authenticity of all instructions, exchanges, P2P transactions and other communications
              sent by you and you are bound by them. We believe that all of your communications
              received through the Website or App are yours and do not verify that they actually
              belong to you. You understand that Instructions, exchanges, P2P transactions, messages
              transmitted through the Website or App are transmitted over the Internet and may be
              routed through publicly accessible transnational installations that are not
              specifically protected from unauthorised access, and you accept these risks.
            </p>
          </li>

          <li>
            <p>
              17.3 You authorise us to deliver all communications, agreements, documents, notices,
              disclosures and confirmations to you via the Website, App or any other electronic
              means we deem appropriate. It is your responsibility to ensure that the details of
              these methods of communication are correct, working and available to receive all
              communications and you must notify us of any changes to the details of the methods of
              communication as soon as possible after the changes have been made. In some cases, our
              communications may only be published on the Website or the App. You will be posted on
              the Website, on the App.
            </p>
          </li>

          <li>
            <p>
              17.4 Communications will be effective from the time they are received or from the time
              they are received (whichever is earlier) unless a later time is specified in the
              communication. Communications shall be deemed to have been received:
              <ul>
                <li>
                  <p>
                    if published/sent on/through the Website or App - at the time of publication;
                  </p>
                </li>

                <li>
                  <p>
                    if by other electronic means accepted by us, when we receive an automated
                    message confirming delivery, or 3 hours after the time of posting unless we
                    receive a receipt stating that delivery is not possible.
                  </p>
                </li>
              </ul>
            </p>
          </li>
        </ol>
        <h2>18. Miscellaneous</h2>
        <ol>
          <li>
            <p>
              18.1 If any provision of the TOU is held unenforceable or invalid, such provision
              shall be modified and construed to achieve the purposes of such provision to the
              fullest extent possible in accordance with applicable law and the remaining provisions
              shall continue in full force and effect.
            </p>
          </li>

          <li>
            <p>
              18.2 Except as provided herein, the failure to exercise a right or enforce an
              obligation under the Terms shall not affect a Party's ability to exercise such right
              or enforce such obligation at any time thereafter, nor shall the waiver of a breach
              constitute a waiver of any subsequent breach.
            </p>
          </li>

          <li>
            <p>
              18.3 If a dispute between the Parties relating to the subject matter of the Agreement
              cannot be resolved by negotiation, the court shall resolve the matter in accordance
              with the terms of the Agreement and the law.
            </p>
          </li>

          <li>
            <p>
              18.4 You may not assign, transfer your rights or obligations under the Agreement
              without our prior written consent. We may assign, transfer our rights and obligations
              without your prior written consent.
            </p>
          </li>

          <li>
            <p>
              18.5 The Agreement constitutes the entire agreement between the parties as to its
              subject matter.
            </p>
          </li>

          <li>
            <p>
              18.6 We may assign, sub-contract or otherwise dispose of your rights and obligations
              under the Agreement without prior notice to you or your consent. You may not transfer,
              sub-contract or otherwise dispose of your rights and obligations under the Agreement
              to another person.
            </p>
          </li>

          <li>
            <p>
              18.7 The Agreement does not create or confer any rights or benefits enforceable by any
              third party other than successors and permitted.
            </p>
          </li>
        </ol>
      </div>
    </div>
  )
}
