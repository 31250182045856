import styles from './LeaderboardItem.module.scss'

import { ReactComponent as MedalIcon } from '@assets/img/svg/medal.svg'
import { ReactComponent as WPIcon } from '@assets/img/svg/wp.svg'
import { ReactComponent as UserIcon } from '@assets/img/svg/user.svg'

import { ESommelierLevel, ICountry, ILeaderboardEntity } from '../../../types/leaderboard.interface'
import { IMAGE_URL } from '../../../contants'
import { useEffect, useState } from 'react'

import { hasFlag } from 'country-flag-icons'
import { COUNTRIES } from '../../../contants/countries'
import CategoryIcon from '../../CategoryIcon/CategoryIcon'

interface IProps {
  data: ILeaderboardEntity
}

export default function LeaderboardItem({ data }: IProps) {
  const [isImgError, setIsImgError] = useState<boolean>(false)
  const [userCountry, setUserCountry] = useState<ICountry | null>(null)

  const handleImgError = () => {
    setIsImgError(true)
  }

  useEffect(() => {
    if (data?.country) {
      const country = COUNTRIES.find(
        (country: any) => country.name === data.country || country.code === data.country
      )

      if (country && hasFlag(country.code)) {
        setUserCountry(country)
      }
    }
  }, [])

  const getSommelierColor = (level: ESommelierLevel) => {
    const map = {
      [ESommelierLevel.STANDARD]: '#F61D0E',
      [ESommelierLevel.MAGNUM]: '#F8601A',
      [ESommelierLevel.JEROBOAM]: '#F89E18',
      [ESommelierLevel.REHOBOAM]: '#F6CC18',
      [ESommelierLevel.METHUSELAH]: '#A8C015',
      [ESommelierLevel.SALMANAZAR]: '#63B43D',
      [ESommelierLevel.BALTHAZAR]: '#1DA0A6',
      [ESommelierLevel.NEBUCHADNEZZAR]: '#2C7EAD',
      [ESommelierLevel.MELCHIOR]: '#4F58A7',
      [ESommelierLevel.SOLOMON]: '#995BA7',
    }

    return map[level]
  }

  return (
    <div className={styles.item}>
      <div className={styles.item__block}>
        <div className={styles.item__index}>{data?.rank || '-'}</div>
        <div className={styles.item__user}>
          <div className={styles.item__avatarWrapper}>
            <div className={styles.item__avatarContainer}>
              {!isImgError ? (
                <img
                  className={styles.item__avatar}
                  src={`${IMAGE_URL}/${data?.avatar}`}
                  alt="Avatar"
                  onError={handleImgError}
                />
              ) : (
                <UserIcon className={styles.item__userIcon} />
              )}
            </div>
            {userCountry?.code && (
              <div className={styles.item__country}>
                <img
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                    userCountry.code || ''
                  }.svg`}
                  alt={userCountry?.name || ''}
                />
              </div>
            )}
          </div>
          <div className="">
            <div className={styles.item__name}>{data?.name || data?.email || '-'}</div>
            {data.level && (
              <div
                className={styles.item__statusContainer}
                style={{ color: getSommelierColor(data?.level) }}
              >
                <div className={styles.item__iconContainer}>
                  <CategoryIcon category={data?.category} />
                </div>
                <p className={styles.item__status}>{data?.level || '-'}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.item__scoreContainer}>
        <WPIcon />
        <p className={styles.item__score}>
          {new Intl.NumberFormat('en-US').format(data?.credits || 0)} WP
        </p>
      </div>
    </div>
  )
}
