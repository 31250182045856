import ReactPaginate from 'react-paginate'
import styles from './Pagination.module.scss'
import { IPagination } from '../../types/pagination.interface'

import { ReactComponent as ArrowIcon } from '@assets/img/svg/arrow.svg'
import useResize from '../../hooks/useResize'

interface IProps {
  pageCount: number
  onPageChange: (page: number) => void
  initialPage?: number
  pagination: IPagination
}

export default function Pagination({ pageCount, initialPage, pagination, onPageChange }: IProps) {
  const { isMobile } = useResize()

  const handlePageChange = ({ selected = 0 }: { selected: number }) => {
    onPageChange(selected + 1)
  }

  return (
    <div className={styles.pagination}>
      <div className={styles.pagination__info}>
        {pagination.page * pagination.limit - pagination.limit + 1}-
        {pagination.page * pagination.limit > pagination.total
          ? pagination.total
          : pagination.page * pagination.limit}{' '}
        of {pagination.total} sommeliers
      </div>
      <div className={styles.pagination__block}>
        <ReactPaginate
          pageCount={pageCount}
          containerClassName={styles.pagination__container}
          pageClassName={styles.pagination__page}
          pageLinkClassName={styles.pagination__pageLink}
          activeClassName={styles.pagination__active}
          previousClassName={styles.pagination__prev}
          nextClassName={styles.pagination__next}
          breakClassName={styles.pagination__break}
          disabledClassName={styles.pagination__disabled}
          disabledLinkClassName={styles.pagination__disabled}
          previousLinkClassName={styles.pagination__pageLink}
          nextLinkClassName={styles.pagination__pageLink}
          previousLabel={
            <div className={styles.pagination__control}>
              <ArrowIcon className={styles.pagination__prevArrow} />
            </div>
          }
          nextLabel={
            <div className={styles.pagination__control}>
              <ArrowIcon className={styles.pagination__nextArrow} />
            </div>
          }
          initialPage={initialPage}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isMobile ? 1 : 3}
          pageLabelBuilder={page => `${page}`}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}
