import styles from './ChestBlock.module.scss'

import img_1 from '@assets/img/1.png'
import img_2 from '@assets/img/2.png'
import img_3 from '@assets/img/3.png'
import img_4 from '@assets/img/4.png'

interface IItem {
  img: string
  title: string
  description: string
  isReverse?: boolean
}

const ChestItem = ({ img, title, description, isReverse }: IItem) => {
  return (
    <div
      id="about"
      className={`${styles.block__item} ${isReverse ? styles['block__item--reverse'] : ''}`}
    >
      <div className={styles.block__itemImgContainer}>
        <img className={styles.block__itemImg} src={img} alt="" />
      </div>
      <div className={styles.block__itemContent}>
        <h3 className={styles.block__itemHeading}>{title}</h3>
        <p className={styles.block__itemText}>{description}</p>
      </div>
    </div>
  )
}

export default function ChestBlock() {
  return (
    <div className={styles.block}>
      <div className={styles.block__container}>
        <ChestItem
          img={img_1}
          title="Self-governed alliance of good wine lovers"
          description="Metawine is more than an app; it's a gateway to a realm where your love for wine transforms into expertise, connection, and endless exploration. It's a platform that empowers wine enthusiasts to become true Sommeliers and industry influencers, offering a revolutionary experience that uncorks a new adventure with every scan"
        />
        <ChestItem
          img={img_2}
          isReverse
          title="Capitalize on your expertise and traction"
          description="Explore our search engine, price comparison tools, and a comprehensive global expertise database—delivering unbiased ratings for millions of wines, all through a simple bottle scan. Dedicated to fulfilling your wine-finding or wine-evaluating needs."
        />
        <ChestItem
          img={img_3}
          title="Connect with the like-you-minded people"
          description="It's more than just a gathering; it's a vibrant community where sommeliers, enthusiasts, and winemakers converge. Celebrate accomplishments, share insights, and toast to the spirit of camaraderie that elevates every pour"
        />
        <ChestItem
          img={img_4}
          isReverse
          title="Your own wine collection marketplace"
          description="Create your own online haven! Metawine allows you to curate your digital pop-up shop with your chosen elixirs. Infuse it with your passion, personalize it to your taste, and share your unique story with fellow aficionados"
        />
      </div>
    </div>
  )
}
