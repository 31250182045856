export interface ILeaderboardEntity {
  rank: number
  level: ESommelierLevel
  credits: number
  name?: string
  avatar?: string
  country?: string
  email?: string
  category?: ECategories | null
}

export interface ILeaderboard {
  page: number
  pages: number
  countItems: number
  entities: ILeaderboardEntity[]
}

export interface ILeaderboardResponse {
  data: ILeaderboard
}

export interface ILeaderboardParams {
  orderBy: 'createdAt'
  order: 'ASC' | 'DESC'
  page: number
  limit: number
}

export interface ICountry {
  name: string
  code: string
}


export enum ESommelierLevel {
  STANDARD = 'Standard',
  MAGNUM = 'Magnum',
  JEROBOAM = 'Jeroboam',
  REHOBOAM = 'Rehoboam',
  METHUSELAH = 'Methuselah',
  SALMANAZAR = 'Salmanazar',
  BALTHAZAR = 'Balthazar',
  NEBUCHADNEZZAR = 'Nebuchadnezzar',
  MELCHIOR = 'Melchior',
  SOLOMON = 'Solomon',
}

export enum ECategories {
  SOMMELIER = 'sommelier',
  CAVISTE = 'caviste',
  WAITER = 'waiter',
  WINE_LOVER = 'wine_lover',
  PRODUCER = 'producer',
  WINEMAKER = 'winemaker',
  GRAPE_GROWER = 'grape_grower',
  EDUCATION = 'education',
  ASSOCIATIONS = 'associations',
  PRESS = 'press',
  INFLUENCER = 'influencer',
  DISTRIBUTOR = 'distributor',
  MARKETER = 'marketer',
  INVESTORS = 'investors',
  OTHERS = 'others',
}