import styles from './Partners.module.scss'

import partner_1 from '@assets/img/partners/1.png'
import partner_2 from '@assets/img/partners/2.png'
import partner_3 from '@assets/img/partners/3.png'

export default function Partners() {
  return (
    <div className={styles.block}>
      <div className={styles.block__container}>
        <h4 className={styles.block__title}>Our Partners</h4>
        <div className={styles.block__list}>
          <a
            href="https://polygon.technology"
            target="_blank"
            rel="noreferrer"
            className={styles.block__itemContainer}
          >
            <img className={styles.block__item} src={partner_1} alt="" />
          </a>
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noreferrer"
            className={styles.block__itemContainer}
          >
            <img className={styles.block__item} src={partner_2} alt="" />
          </a>
          <a
            href="https://www.meta.com"
            target="_blank"
            rel="noreferrer"
            className={styles.block__itemContainer}
          >
            <img className={styles.block__item} src={partner_3} alt="" />
          </a>
        </div>
      </div>
    </div>
  )
}
