import { useState } from 'react'
import TypeWriter from '../TypeWriter/TypeWriter'
import styles from './HeroBanner.module.scss'

import video from '@assets/video/video_bg.mp4'

import { ReactComponent as CupIcon } from '@assets/img/svg/cup.svg'

export default function HeroBanner() {
  const [isShowDescription, setIsShowDescription] = useState(false)
  const [isShowButton, setIsShowButton] = useState(false)

  const showButton = () => {
    setIsShowButton(true)
  }

  const showDescription = () => {
    setIsShowDescription(true)
  }
  return (
    <div className={styles.block}>
      <div className={styles.block__container}>
        <div className={styles.block__content}>
          <TypeWriter
            className={styles.block__heading}
            content="Decentralized Autonomous Wine Alliance"
            speed={100}
            onEnd={showDescription}
          />

          {isShowDescription && (
            <TypeWriter
              className={styles.block__text}
              content="Join our white list to become a wine ambassador and enjoy early bird merits and Metawine
            retrodrop"
              speed={60}
              onEnd={showButton}
            />
          )}

          {isShowButton && (
            <>
              <a
                href={process.env.REACT_APP_PLATFORM_URL}
                target="_blank"
                rel="noreferrer"
                className={`${styles.block__button} ${
                  isShowButton ? styles['block__button--shown'] : ''
                }`}
              >
                Get Started
              </a>
              <div className={styles.block__cupContainer}>
                <CupIcon className={styles.block__icon} />
                <p className={styles.block__lottery}>
                  Take part in the immediate lottery <br /> and receive from 25 to 500 wine points
                </p>
              </div>
            </>
          )}
        </div>
        <div className={styles.block__videoContainer}>
          <video
            className={styles.block__video}
            src={video}
            autoPlay
            muted
            playsInline
            loop
          ></video>
        </div>
      </div>
    </div>
  )
}
