export default function PrivacyContent() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <h3>Metawine.pro </h3>
      <p>
        This Privacy Policy ("Privacy Policy") sets out the security measures that BUSINESS
        TECHNOLOGY INVESTMENT LTD (a company incorporated Unit B Broad Oak Business Park, Redbrook
        Maelor, Whitchurch, Shropshire, United Kingdom, registration number 06795173, is the owner
        and developer of the Metawine.pro platform, website, application.
      </p>
      <p>
        Metawine ("we", "us" or "our") applies to protect and manage the personal data of users of
        the Metawine platform, website and/or app in accordance with applicable data privacy
        regulations and this Privacy Policy.
      </p>
      <p>
        Your privacy is extremely important to us and we make every effort to protect it. The
        Privacy Policy explains how we use your personal data.
      </p>
      <p>
        All capitalised terms not otherwise defined in the Privacy Policy have the same meanings as
        in the Terms and Conditions.
      </p>
      <div>
        <h2>1. Definitions.</h2>
        <p>
          The following terms have the meaning defined below: "Application" means the web
          application software with all updates, upgrades, versions, etc.) developed, owned by us
          that allows you to access our Services, Products. "Metawine.pro Platform" means a website
          and application based digital platform created by Metawine that provides numerous tools,
          features and allows users to interact, operate within the functionality of the platform
          and access the Partner Services. The Metawine.pro platform is integrated with the
          Partners' services platforms to allow users to utilise the Partners' services.
          <br /> <br />
          "Partner Services" means the services of opening, maintaining and closing account
          accounts, processing payments to and from a card account, and other services provided by
          Partners. <br /> <br />
          "Personal Data" means data consisting of information relating to a living natural person
          who can be identified or identifiable on the basis of that information (or on the basis of
          that and other information held by the data user), including any expression of opinion
          about the natural person, but not any indication of the data user's intentions in relation
          to that natural person. An identifiable natural person is a person who can be directly or
          indirectly identified, in particular by an identifier such as a name, identification
          number, location data, online identifier or by one or more factors specific to the
          physical, physiological, genetic, mental, economic, cultural or social identity of that
          natural person. <br /> <br />
          "User" means any person using our Website and/or Application, Services or Products.
          <br /> <br />
          "Website" means metawine.pro <br /> <br />
          "You", "your" means you if you are our User, or if our User is a legal entity, you are
          acting as a representative, owner, ultimate beneficial owner, etc. of such legal entity,
          or you are contacting us through digital communication methods.
        </p>

        <h2>2. General Provisions..</h2>
        <p>
          We offer Metawine services and products ("Services", "Products") in accordance with our
          Terms and Conditions. <br /> <br /> We use Personal Data only for the purposes set out in
          the Privacy Policy and to provide you or an entity that is our User (if you are acting as
          a representative, owner, ultimate beneficial owner, etc.) with our services, products.{' '}
          <br /> <br /> We share your Personal Data with third parties only if it is necessary for
          the provision of services, products or required by law.
        </p>

        <h2>3. Treatment of Personal Data.</h2>
        <p>
          We value your privacy. The Privacy Policy contains important information regarding the
          processing, namely the collection, recording, organisation, structuring, storage,
          adaptation or modification, supplementation, retrieval, consultation, use, disclosure,
          dissemination, distribution, restriction, erasure, deletion, rearrangement, etc. of your
          Personal Data collected on the Website, App or otherwise. <br /> <br />
          We have developed a Privacy Policy to help you make an informed decision about whether or
          not to use the Website, App. <br /> <br />
          We reserve the right to update the Privacy Policy at any time. <br /> <br />
          In the event of such an update, its new version will be posted on the Website, App. We
          will not give you adequate notice of such update and your or our User's legal entity
          (where you are acting as a representative, or owner, ultimate beneficial owner, etc.)
          continued use of the Website, Application will be interpreted as your acceptance of the
          new version. We process Personal Data to the extent sufficient for the following purposes:
          <br /> <br />
          <ul>
            <li>
              <p>
                Contractual purposes. To enter into a contract for the service, sale, its fulfilment
                and provision of Services, Products to you or an entity that is our User (where you
                act as a representative, owner, ultimate beneficial owner, etc.) (which may require
                disclosure to relevant third parties as we have determined), for the proper access
                and qualitative improvement of the features of our Site, Application;
              </p>
            </li>

            <li>
              <p>
                Analytical, operational, administrative and promotional purposes. To gather general
                and specific statistics about the use of the Website, Application, to send messages,
                notices, advertisements, updates, surveys, other marketing purposes, customer
                support and ancillary purposes;
              </p>
            </li>
            <li>
              <p>
                Legal Compliance Purpose. Where required by law and to the extent permitted by law,
                to review your Personal Data for the purposes of managing customer relationships and
                complying with Know Your Customer rules, to comply with regulatory obligations
                relating to anti-money laundering (AML), counter terrorist financing (CTF) and other
                laws. We process Personal Data only for the purposes set out in the Privacy Policy
                or as required by applicable law.
              </p>
            </li>
          </ul>
          The legal basis for processing is: <br /> <br />
          <ul>
            <li>
              <p>consent given as set out in our Privacy Policy;</p>
            </li>
            <li>
              <p>
                the execution of an agreement based on our Terms to which you or a legal person who
                is our User (where you are the representative, owner or ultimate beneficial owner,
                etc.) is a party, or the taking of measures necessary prior to entering into an
                agreement based on our Terms. where required by law and to the extent required by
                law to fulfil legal obligations to which we are subject, including but not limited
                to AML/CFT requirements;
              </p>
            </li>
            <li>
              <p>to protect the vital interests of you or another person;</p>
            </li>
            <li>
              <p>other basis provided by law.</p>
            </li>
          </ul>
          By using our Services, Products or our Site, App by you or a legal entity that is our User
          (where you act as a representative, owner, ultimate beneficial owner, etc.), you agree to
          the Privacy Policy and consent to the processing of your Personal Data in accordance with
          this Policy and applicable law.
        </p>

        <h2>4. Types of Personal Data.</h2>
        <p>We categorise our Personal Data into the following three types:</p>
        <ol>
          <li>
            <p>
              {' '}
              <b>4.1 Directly Collected Personal Data.</b>{' '}
            </p>
            <p>
              This is information that the User provides to us directly (for example, by filling in
              forms on the Site).
            </p>
          </li>
          <li>
            <p>
              <b>4.2 Indirectly collected Personal Data</b>{' '}
            </p>
            <p>
              This is information that relates to the User, but he/she does not provide it directly
              (e.g. IP address).
            </p>
          </li>

          <li>
            <p>
              {' '}
              <b>4.3 Automatically collected / generated Personal Data</b>{' '}
            </p>
            <p>
              Our system collects this type of information automatically. Although this information
              is not provided by the User, it relates to you and is considered Personal Data. It has
              2 sub-types: Static. This information does not usually change once it is created. For
              example, an internal User ID; <br /> <br /> Dynamic. This subtype changes depending on
              the actions you perform.
            </p>
          </li>
        </ol>

        <h2>5. What personal data do we collect?</h2>
        <p>
          The following personal data about you may be collected for the purposes of processing
          personal data as set out above:
          <br /> <br />
          <ul>
            <li>
              <p>
                Name and surname, date of birth, residential address, postal address, email address,
                telephone number, nationality, Personal data collected from public sources,
                information about whether or not you are a politically exposed person;
              </p>
            </li>

            <li>
              <p>
                Identity card data, passport or other identification document, your occupation,
                industry of employment, financial situation, biometric identifiers and biometric
                information;
              </p>
            </li>

            <li>
              <p>
                Verification data (if required by law and to the extent permitted by law): your
                photograph, video recording taken during verification, data we collect for the
                purpose of conducting verification of the User in accordance with applicable laws
                and AML/CFT regulations;
              </p>
            </li>

            <li>
              <p>
                confirmation that you are the representative, owner, ultimate beneficial owner, etc.
                of the legal entity that is our User, information about the ownership and control
                structure of the User;
              </p>
            </li>

            <li>
              <p>
                Computer and connection information such as browser type, version and time zone
                settings, browser plug-in types and versions, operating system and platform, domain,
                host, IP address, date and time you access our Site or App, the address of the
                website from which you linked to our Site when you visit us;
              </p>
            </li>

            <li>
              <p>
                Mobile device data (e.g. location), unique identifiers of your devices when you use
                web, Android and/or IOS mobile applications;
              </p>
            </li>

            <li>
              <p>Your behaviour, preferences, interests, favourites;</p>
            </li>

            <li>
              <p>
                Customer Support Data: communications between us and you (enquiries sent via
                website, email, social media or chat);
              </p>
            </li>
            <li>
              <p>
                Financial Information: bank details, wallet details, bank card numbers and other
                card details, information about transactions, transfers, transaction logs, billing,
                payment details and other financial and billing data collected by us when you or a
                legal entity - our User (where you act as a representative, owner, ultimate
                beneficial owner, etc.) use our Services, Products.
              </p>
            </li>
          </ul>
          This information is not exhaustive and is provided to give you an idea of the use of the
          fundamental information collected. We will record all information collected and the
          purposes for which we process this Personal Data.
        </p>

        <h2>6. Lawfulness of processing and disclosure of information.</h2>

        <p>
          By using our Site, App, our Services, Products, you agree to this Privacy Policy and
          consent to the processing of your Personal Data as set out herein. If your Personal Data
          is provided by a legal entity that is our User (where you are acting as a representative,
          owner, ultimate beneficial owner, etc.), such User is responsible for and must be
          satisfied that it has obtained your consent to share your Personal Data with us. <br />{' '}
          <br />
          We take all reasonable steps to ensure that your Personal Data is processed securely in
          accordance with this Privacy Policy when we process your Personal Data for one of the
          lawful purposes set out in this Privacy Policy. We protect your Personal Data in
          accordance with internationally recognised standards by applying physical, technological
          and administrative security measures to minimise the risks of loss, misuse, unauthorised
          access, disclosure and alteration. Such measures include firewalls and data encryption, as
          well as physical access controls to our data centres and information access authorisation
          controls. <br /> <br /> We also restrict access to personal data to only those employees
          or contractors who need it to fulfil their job or official duties. Our employees and
          contractors are trained in the procedures for processing personal data, including
          restrictions on the release of information. <br /> <br /> Only those of our employees and
          contractors whose jobs require access to personal data. <br /> <br /> We conduct periodic
          assessments to ensure that appropriate information handling policies and procedures are
          understood and followed. All of our physical, electronic and procedural safeguards are
          designed to comply with applicable laws and regulations. <br /> <br /> When you submit
          your personal data via our website, application, it is securely transmitted over the
          internet using standard encryption. Your personal data will be stored in encrypted form on
          secure servers.
        </p>

        <h2>7. Third Parties.</h2>
        <p>
          We have taken steps to ensure that third parties engaged by us to process your Personal
          Data take appropriate technical and organisational measures to protect that Personal Data
          and we will also ensure that they comply with legal requirements. We have taken measures
          to ensure that third parties engaged by us protect the confidentiality and security of
          Personal Data and ensure that Personal Data is only processed for the purposes set out
          above and in accordance with applicable law. <br /> <br /> We may share your Personal Data
          with third parties such as:
          <br />
          <br />
          <ul>
            <li>
              <p>
                legal and regulatory authorities to whom we are required to disclose your Personal
                Data by law;
              </p>
            </li>
            <li>
              <p>law enforcement agencies;</p>
            </li>

            <li>
              <p>
                organisations in cases of fraud or collusion prevention, identity verification,
                payment processing, credit reference where required by law;
              </p>
            </li>

            <li>
              <p>courts;</p>
            </li>
            <li>
              <p>
                identification and identity verification service providers engaged by us to deal
                with Users, verify you, fulfil AML and TF requirements where required by law;
              </p>
            </li>
            <li>
              <p>
                server hosts where our data is stored, communication service providers who help us
                to stay in touch with you;
              </p>
            </li>

            <li>
              <p>providers of marketing, advertising services;</p>
            </li>
            <li>
              <p>
                banking, financial service providers who assist us in providing services, products
                to you or our user entity (where you act as a representative, owner, ultimate
                beneficial owner, etc.);
              </p>
            </li>

            <li>
              <p>
                suppliers and contractors/subcontractors, employees/contractors who may need
                information to provide services to us;
              </p>
            </li>

            <li>
              <p>
                other third parties whose services, facilities we use to provide our Services,
                Products and to perform certain processes necessary to operate the Website,
                Application, to fulfil internal procedures and to comply with legal requirements.
              </p>
            </li>
          </ul>
          Each third-party processing Personal Data shall ensure that: <br /> <br />
          <ul>
            <li>
              <p>the persons processing the data have a duty of confidentiality;</p>
            </li>
            <li>
              <p>it has taken appropriate measures to ensure the security of the processing;</p>
            </li>
            <li>
              <p>it must provide reasonable assistance in granting access to the data subject;</p>
            </li>

            <li>
              <p>
                allows data subjects to exercise their rights under personal data protection
                legislation;
              </p>
            </li>

            <li>
              <p>deletes or returns all Personal Data upon request;</p>
            </li>

            <li>
              <p>
                provides auditors and inspectorates with any information necessary to ensure
                compliance with personal data protection laws;
              </p>
            </li>

            <li>
              <p>
                does not process (or cause to be processed) any Personal Data in a country that is
                not recognised as providing an adequate level of data protection, unless it has
                taken the measures necessary to ensure that such transfer complies with personal
                data protection laws, except as otherwise required by applicable law notifies as
                soon as possible if it becomes aware of any unauthorised or unlawful processing,
                loss, damage or destruction of Personal Data;
              </p>
            </li>

            <li>
              <p>
                takes appropriate technical and organisational measures against unauthorised or
                unlawful processing of Personal Data or accidental loss, destruction or damage, etc.
              </p>
            </li>
          </ul>
          We are required to disclose your Personal Data in the following cases: <br /> <br />
          <ul>
            <li>
              <p>
                In response to lawful requests by public authorities, including to fulfil legitimate
                national security or law enforcement requirements;
              </p>
            </li>

            <li>
              <p>
                to protect, establish or exercise our legal rights or defend against legal claims,
                including to collect a debt;
              </p>
            </li>

            <li>
              <p>to comply with a subpoena, court order, legal process or other lawful demand;</p>
            </li>

            <li>
              <p>
                if we believe in good faith that such disclosure is necessary to comply with the
                law, to prevent imminent physical harm or financial loss, or to investigate, prevent
                or take action regarding illegal activity, suspected fraud, threats to our property
                or violations of the Terms and Conditions;
              </p>
            </li>

            <li>
              <p>
                to third parties in the event of: a merger, restructuring, joint venture,
                assignment, sale of part or all of the business.
              </p>
            </li>
          </ul>
        </p>

        <h2>8. Privacy when using cryptocurrencies and blockchain.</h2>
        <p>
          Your cryptocurrencies may be recorded on a public blockchain. Public blockchains are
          distributed ledgers designed to record transactions across wide networks of computer
          systems in an immutable form. Many blockchains are open to forensic analysis, which can
          lead to de-anonymisation and inadvertent disclosure of private financial information,
          especially when blockchain data is matched with other data. <br /> <br /> We cannot erase,
          alter or modify Personal Data from such networks because blockchain is a decentralised or
          third-party network that is not controlled or managed by us.
        </p>

        <h2>9. Where do we store Personal Data?</h2>
        <p>
          Personal Data collected about our Users, i.e. you, will be stored on a server in a country
          that is not recognised as providing an adequate level of data protection, unless it has
          taken measures necessary to ensure that such transfer complies with personal data
          protection laws, unless otherwise required by applicable law. To ensure the security and
          confidentiality of your personal data, we take measures designed to protect this
          information from loss, misuse, unauthorised access, disclosure, alteration or destruction.
        </p>

        <h2>10. How long do we keep Personal Data for?</h2>
        <p>
          We keep Personal Data for as long as it is processed by us, third parties engaged by us to
          provide Services, Products.
        </p>

        <h2>11. Notices.</h2>
        <p>
          In the event of a Personal Data leak, we must notify the competent supervisory authority
          without undue delay after we become aware of it, unless the Personal Data leak is unlikely
          to result in a risk to the rights and freedoms of individuals. <br /> <br /> If we become
          aware of a security breach, we may endeavour to notify you electronically so that you can
          take appropriate preventative measures. If a security breach occurs, we may make a notice
          on the Website, in the App. <br /> <br /> If a breach of personal data is expected to
          result in a high risk to users, your rights and freedoms, we will notify you. <br />
          <br /> If we are acquired by or merged with a third party, we reserve the right to
          transfer or assign the data we collect from our Users as part of such merger, acquisition,
          sale or other change of control. If we are involved in a merger, acquisition or any form
          of sale of some or all of our assets, we will notify Users before personal data is
          transferred and becomes subject to a different privacy policy. We will not be able to
          control the processing, transfer or use of your personal data in the unlikely event of our
          bankruptcy, insolvency, reorganisation, administration or transfer for the benefit of
          creditors, or the application of laws or equitable principles affecting creditors' rights
          generally.
        </p>

        <h2>12. Your Rights.</h2>
        <ol>
          <li>
            <p>
              {' '}
              <b>12.1 Obtain confirmation</b>{' '}
            </p>
            <p>
              You have the right to request confirmation that personal data relating to you is being
              processed.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b>12.2 Access to personal data</b>{' '}
            </p>
            <p>
              You have the right to access such data or to ask us for a copy of your personal data
              processed by us.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b> 12.3 Request for information</b>
            </p>
            <p>
              You have the right to request the following additional information relating to your
              Personal Data:
              <ul>
                <li>
                  <p>the purposes of the processing;</p>
                </li>
                <li>
                  <p>the relevant categories of Personal Data;</p>
                </li>
                <li>
                  <p>
                    the recipient(s) or category(s) of recipients to whom the Personal Data has been
                    or will be disclosed;
                  </p>
                </li>

                <li>
                  <p>
                    the criteria determining the period for which the Personal Data will be stored,
                    etc.
                  </p>
                </li>
              </ul>
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b> 12.4 Request for rectification</b>
            </p>
            <p>
              You have the right to have Personal Data rectified if it is incorrect or incomplete.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b>12.5 Right to Withdrawal</b>{' '}
            </p>
            <p>
              You have the right to withdraw the consent previously provided and delete the Personal
              Data from our system. In this case, third parties will no longer have access to your
              Personal Data. However, this does not mean that your data will be deleted immediately;
              it will still be stored by us in order to comply with numerous legal obligations, in
              particular the provision of the regulation that we may keep any information collected
              for a period of 5 years from the termination of the relationship. After this period,
              your personal data will be deleted from our records.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b>12.6 Limitation of Processing</b>{' '}
            </p>
            <p>
              If the accuracy of Personal Data is disputed by you, you have the right to restrict
              processing for a period to allow us to verify the accuracy of the Personal Data.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b>12.7 Receipt of Personal Data</b>{' '}
            </p>
            <p>
              You have the right to receive Personal Data relating to you provided to us in a
              structured, commonly used and machine-readable format. We are required to and have
              provided all information you are entitled to receive. This Privacy Policy finalises
              all information and is available to you on our website at any time.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b>12.8 Transfer of Personal Data</b>{' '}
            </p>
            <p>
              You have the right to have personal data transferred directly from one controller to
              another, if technically possible.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <b>12.9 Objection</b>{' '}
            </p>
            <p>
              You have the right to object, on the basis of your particular situation, if there is
              reason to believe that we have no lawful basis for processing Personal Data, at any
              time to the processing of Personal Data relating to her or him.
            </p>
          </li>
          <li>
            <p>
              <b>12.10 Complaintƒ</b>{' '}
            </p>
            <p>
              You have the right to lodge a complaint in relation to the processing of your Personal
              Data. You have the right to lodge a complaint with the relevant supervisory authority.
            </p>
          </li>
        </ol>

        <h2>13. Communications.</h2>
        <p>
          Throughout our co-operation, you will receive informative communications from us and you
          may also send us communications via the Feedback Forms available on the Metawine Platform.
        </p>

        <h2>14. Making Changes.</h2>
        <p>
          Our Privacy Policy is reviewed on a regular basis to ensure that it takes into account any
          new obligations and technologies, as well as any changes in our business operations and
          practices, and that it stays current with the changing regulatory environment. Any
          personal data held by us will be governed by our most recent Privacy Policy. <br /> <br />{' '}
          If we decide to update our Privacy Policy, we will post those changes here and in such
          other places as we deem appropriate.
        </p>

        <h2>15. Conclusion.</h2>
        <p>
          Please note that if you exercise certain rights granted by law (erasure, stop processing),
          you will be deprived of certain features of the Website, Application, and in some cases,
          where applicable, we may be forced to terminate your account on the Website, Application.
          <br /> <br />
          Notwithstanding the rights you have exercised, we may in any event continue to process
          your Personal Data if required to do so by law. <br /> <br /> We are committed to
          protecting the confidentiality of Personal Data and endeavour to disclose details of
          processing in a clear and easy to understand manner. <br /> <br /> If you have any
          questions regarding the above information, please do not hesitate to contact us as set out
          above. <br /> <br /> If we lawfully obtain your personal data from a third party, you will
          have the same rights in relation to that information as you have in relation to
          information that you have provided to us directly or that we have collected in the course
          of co-operating with you. <br /> <br /> If you have a reasonable belief that we are in
          breach of our obligations to protect your privacy, you have the right to lodge a complaint
          with the relevant supervisory authority.
        </p>
      </div>
    </div>
  )
}
