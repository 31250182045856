import styles from './JoinUs.module.scss'
import worldMapImg from '@assets/img/world_map.png'
import { ReactComponent as LogoIcon } from '@assets/img/svg/logo.svg'

export default function JoinUs() {
  return (
    <div className={styles.block}>
      <div className={styles.block__container}>
        <div className={styles.block__imgContainer}>
          <img className={styles.block__bgImage} src={worldMapImg} alt="" />
        </div>
        <div className={styles.block__content}>
          <LogoIcon />
          <p className={styles.block__text}>
            Join our white list to become a wine ambassador and enjoy early bird merits and Metawine
            retrodrop
          </p>
          <button className="button button--black">
            <a
              href={process.env.REACT_APP_PLATFORM_URL}
              target="_blank"
              className={styles.block__button}
            >
              Get Started
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}
