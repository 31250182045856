import { Link, useLocation } from 'react-router-dom'
import styles from './Header.module.scss'

import { ReactComponent as LogoIcon } from '@assets/img/svg/logo.svg'

export default function Header() {
  const location = useLocation()

  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <Link to="/" className={styles.header__logoContainer}>
          <LogoIcon />
        </Link>

        {location.pathname === '/' && (
          <nav className={styles.header__nav}>
            <a className={styles.header__link} href="#about">
              About
            </a>

            <a className={styles.header__link} href="#leaderboard">
              Leaderboard
            </a>
            <a className={styles.header__link} href="#contacts">
              Contact Us
            </a>
          </nav>
        )}

        <div className={styles.header__block}>
          <a
            href={process.env.REACT_APP_PLATFORM_URL}
            target="_blank"
            rel='noreferrer'
            className={styles.header__button}
          >
            Login
          </a>
        </div>
      </div>
    </header>
  )
}
