import ChestBlock from '../components/ChestBlock/ChestBlock'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import HeroBanner from '../components/HeroBanner/HeroBanner'
import JoinUs from '../components/JoinUs/JoinUs'
import Leaderboard from '../components/Leaderboard/Leaderboard'
import Partners from '../components/Partners/Partners'

export default function Homepage() {
  return (
    <>
      <Header />
      <HeroBanner />
      <ChestBlock />
      <JoinUs />
      <Leaderboard />
      <Partners />
      <Contact />
      <Footer />
    </>
  )
}
