import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'

import { ReactComponent as LogoIcon } from '@assets/img/svg/logo.svg'
import { ReactComponent as FacebookIcon } from '@assets/img/svg/facebook.svg'
import { ReactComponent as InstagramIcon } from '@assets/img/svg/instagram.svg'
import { ReactComponent as XIcon } from '@assets/img/svg/x.svg'
import { ReactComponent as LinkedinIcon } from '@assets/img/svg/linkedin.svg'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__logoContainer}>
          <LogoIcon />
        </div>
        <div className={styles.footer__socials}>
          <a href="https://x.com/metawinepro?s=21" target="_blank" className={styles.footer__social}>
            <XIcon />
          </a>

          <a href="https://www.linkedin.com/company/metawine-pro/" target="_blank" className={styles.footer__social}>
            <LinkedinIcon />
          </a>

          <a href="https://www.facebook.com/profile.php?id=61556403364338" target="_blank" className={styles.footer__social}>
            <FacebookIcon />
          </a>

          <a href="https://www.instagram.com/metawine.pro?igsh=MXYwejFuanhlaTR6bQ==" target="_blank" className={styles.footer__social}>
            <InstagramIcon />
          </a>
        </div>
        <div className={styles.footer__copyright}>
          © Copyright 2024, All Rights Reserved by Metawine LTD.
        </div>
        <div className={styles.footer__links}>
          <Link to="/privacy-policy" className={styles.footer__link}>
            Privacy Policy
          </Link>
          <Link to="/terms" className={styles.footer__link}>
            Terms & Conditions
          </Link>
        </div>
      </div>
    </footer>
  )
}
