import { useFormik } from 'formik'
import axiosInstance from '../../api/config'
import styles from './Contact.module.scss'

import * as Yup from 'yup'
import { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

interface ISupportBody {
  email: string
  name: string
  message: string
}

const SupportSchema = Yup.object({
  email: Yup.string().max(255).email().required().trim(),
  name: Yup.string()
    .max(255)
    .matches(/^[A-Za-z\s]*$/)
    .required(),
  message: Yup.string().max(300).required(),
})

export default function Contact() {
  const [isPending, setIsPending] = useState(false)
  const recaptchaRef = useRef<any>(null)

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
    },
    validationSchema: SupportSchema,
    onSubmit: async (values: any) => {
      await submitForm(values)
    },
  })

  const submitForm = async (body: ISupportBody) => {
    setIsPending(true)

    const token = await recaptchaRef.current.executeAsync()

    try {
      await axiosInstance({
        method: 'post',
        url: '/api/supports',
        data: {
          email: body.email.trim(),
          name: body.name,
          message: body.message,
        },
        headers: {
          recaptcha: token,
        },
      })

      setIsPending(false)
      formik.resetForm()
    } catch (e: any) {
      console.log(e)
      setIsPending(false)
    }
  }

  const handleNoSpecialCharacters = (value: string) => {
    return value.replace(/[^a-zA-Z0-9 ]/g, '')
  }

  return (
    <div id="contacts" className={styles.block}>
      <div className={styles.block__container}>
        <h5 className={styles.block__heading}>Get in touch</h5>
        <p className={styles.block__description}>We will answer you within a business day</p>
        <form className={styles.block__form} onSubmit={formik.handleSubmit}>
          <div className={styles.block__grid}>
            <div className={styles.block__col}>
              <div className={styles.block__row}>
                <label htmlFor="fullName" className={styles.block__label}>
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="eg: John Doe"
                  className={`${styles.block__input} ${
                    formik.touched.name && Boolean(formik.errors.name)
                      ? styles['block__input--invalid']
                      : ''
                  }`}
                  maxLength={255}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className={styles.block__row}>
                <label htmlFor="email" className={styles.block__label}>
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="eg: john@email.com "
                  className={`${styles.block__input} ${
                    formik.touched.email && Boolean(formik.errors.email)
                      ? styles['block__input--invalid']
                      : ''
                  }`}
                  maxLength={255}
                  value={formik.values.email.trim()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className={styles.block__col}>
              <div className={styles.block__row}>
                <div className={styles.block__flex}>
                  <label htmlFor="message" className={styles.block__label}>
                    Share Your Message
                  </label>
                  <p className={styles.block__symbols}>{formik.values.message.length}/300</p>
                </div>

                <div className={styles.block__textareaContainer}>
                  <textarea
                    placeholder="Message"
                    name="message"
                    className={`${styles.block__input} ${
                      formik.touched.message && Boolean(formik.errors.message)
                        ? styles['block__input--invalid']
                        : ''
                    }`}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    maxLength={300}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          />
          <button
            className={`button button--black ${styles.block__button}`}
            type="submit"
            disabled={isPending}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
