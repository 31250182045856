import styles from './Spinner.module.scss'

interface IProps {
  size?: number
  color?: 'black' | 'white'
}

export default function Spinner({ size = 48, color = 'black' }: IProps) {
  return (
    <div
      className={`${styles['sk-circle']} ${styles[`sk-circle--${color}`]}`}
      style={{ width: size, height: size }}
    >
      <div className={`${styles['sk-circle1']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle2']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle3']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle4']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle5']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle6']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle7']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle8']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle9']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle10']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle11']} ${styles['sk-child']}`}></div>
      <div className={`${styles['sk-circle12']} ${styles['sk-child']}`}></div>
    </div>
  )
}
