import { ReactComponent as SommelierIcon } from '@assets/img/svg/categories/sommelier.svg'
import { ReactComponent as CavisteIcon } from '@assets/img/svg/categories/caviste.svg'
import { ReactComponent as WaiterIcon } from '@assets/img/svg/categories/waiter.svg'
import { ReactComponent as WineLoverIcon } from '@assets/img/svg/categories/wine_lover.svg'
import {ReactComponent as ProducerIcon} from '@assets/img/svg/categories/producer.svg'
import {ReactComponent as WinemakerIcon} from '@assets/img/svg/categories/winemaker.svg'
import {ReactComponent as GrapeGrowerIcon} from '@assets/img/svg/categories/grape_grower.svg'
import {ReactComponent as EducationIcon} from '@assets/img/svg/categories/education.svg'
import {ReactComponent as AssociationsIcon} from '@assets/img/svg/categories/associations.svg'
import {ReactComponent as PressIcon} from '@assets/img/svg/categories/press.svg'
import {ReactComponent as InfluencerIcon} from '@assets/img/svg/categories/influencer.svg'
import {ReactComponent as DistributorIcon} from '@assets/img/svg/categories/distributor.svg'
import {ReactComponent as MarketerIcon} from '@assets/img/svg/categories/marketer.svg'
import {ReactComponent as InvestorsIcon} from '@assets/img/svg/categories/investors.svg'
import {ReactComponent as OthersIcon} from '@assets/img/svg/categories/others.svg'
import { ECategories } from '../../types/leaderboard.interface'

interface IProps {
  category?: ECategories | null
}

export default function CategoryIcon({ category }: IProps) {
  switch (category) {
    case ECategories.SOMMELIER:
      return <SommelierIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.CAVISTE:
      return <CavisteIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.WAITER:
      return <WaiterIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.WINE_LOVER:
      return <WineLoverIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.PRODUCER:
      return <ProducerIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.WINEMAKER:
      return <WinemakerIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.GRAPE_GROWER:
      return <GrapeGrowerIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.EDUCATION:
      return <EducationIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.ASSOCIATIONS:
      return <AssociationsIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.PRESS:
      return <PressIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.INFLUENCER:
      return <InfluencerIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.DISTRIBUTOR:
      return <DistributorIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.MARKETER:
      return <MarketerIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.INVESTORS:
      return <InvestorsIcon style={{ width: '100%', height: '100%' }} />
    case ECategories.OTHERS:
      return <OthersIcon style={{ width: '100%', height: '100%' }} />
    default:
      return <WineLoverIcon style={{ width: '100%', height: '100%' }} />
  }
}
