import { useEffect } from 'react'
import DocumentContent from '../../components/DocumentContent/DocumentContent'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import TermsContent from '../../components/TermsContent/TermsContent'

export default function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <DocumentContent>
        <TermsContent />
      </DocumentContent>
      <Footer />
    </>
  )
}
