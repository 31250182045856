import { useEffect, useState } from 'react'
import styles from './TypeWriter.module.scss'

interface IProps {
  content: string
  speed?: number
  className?: string
  onEnd?: () => void
}

export default function TypeWriter({
  content = '',
  speed = 100,
  className,
  onEnd = () => {},
}: IProps) {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    if (currentIndex < content.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + content[currentIndex])
        setCurrentIndex(prevIndex => prevIndex + 1)
      }, speed)

      return () => clearTimeout(timeout)
    }

    if (currentIndex === content.length && !isEnd) {
      onEnd()
      setIsEnd(true)
    }
  }, [currentIndex, speed, content])

  return (
    <div className={`${styles.block} ${isEnd ? styles['block--end'] : ''} ${className}`}>
      {currentText}
    </div>
  )
}
