import { useEffect, useState } from 'react'
import Pagination from '../Pagination/Pagination'
import styles from './Leaderboard.module.scss'
import LeaderboardItem from './LeaderboardItem/LeaderboardItem'
import { IPagination } from '../../types/pagination.interface'
import axiosInstance from '../../api/config'
import {
  ILeaderboardEntity,
  ILeaderboardParams,
  ILeaderboardResponse,
} from '../../types/leaderboard.interface'
import Spinner from '../Spinner/Spinner'

export default function Leaderboard() {
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    limit: 10,
    pages: 1,
    total: 1,
  })
  const [items, setItems] = useState<ILeaderboardEntity[]>([])
  const [isLoading, setisLoading] = useState(true)

  const handlePageChange = async (page: number) => {
    if (page === +pagination.page) return

    await fetchData(page)

    // document.getElementById('leaderboard')?.scrollIntoView()
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async (page: number = 1) => {
    try {
      let params: ILeaderboardParams = {
        order: 'ASC',
        orderBy: 'createdAt',
        page,
        limit: pagination.limit,
      }

      const { data } = await axiosInstance.get<ILeaderboardResponse>('/api/users/leaderboard', {
        params,
      })

      setItems(data.data.entities)
      setPagination(prev => {
        return {
          ...prev,
          page: data.data.page,
          pages: data.data.pages,
          total: data.data.countItems,
        }
      })
      setisLoading(false)
    } catch (e: any) {
      setisLoading(false)
    }
  }

  return (
    <div id="leaderboard" className={styles.block}>
      <div className={styles.block__container}>
        <p className={styles.block__heading}>Leaderboard</p>

        {isLoading ? (
          <div className={styles.block__loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {items.length < 1 && <p className={styles.block__empty}>Nothing yet</p>}
            <div className={styles.block__list}>
              {items.map((item: ILeaderboardEntity) => (
                <LeaderboardItem key={item.rank} data={item} />
              ))}
            </div>
            {pagination.pages > 1 && (
              <div className={styles.block__pagination}>
                <Pagination
                  pageCount={pagination.pages}
                  initialPage={0}
                  pagination={pagination}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
