import { Link } from 'react-router-dom'
import styles from './ErrorPage.module.scss'

import { ReactComponent as LogoIcon } from '@assets/img/svg/logo.svg'

export default function ErrorPage() {
  return (
    <div className={styles.page}>
      <div className={styles.page__header}>
        <Link to="/" className={styles.page__link}>
          <LogoIcon />
        </Link>
      </div>
      <div className={styles.page__content}>
        <p className={styles.page__code}>404</p>
        <h2 className={styles.page__heading}>Page not found</h2>
        <p className={styles.page__text}>
          Oops! The page you are looking for does not exist. It might have been moved or deleted
        </p>
        <a href="/" className={`button button--black ${styles.page__button}`}>
          Back to Home
        </a>
      </div>
    </div>
  )
}
